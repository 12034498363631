<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#2F2F2F"
        height="36"
        rx="6"
        width="50"
      />
      <path
        d="M25.0834 25.825C27.2511 23.8284 28.6202 20.9761 28.6202 17.8386C28.6202 14.6441 27.2511 11.7918 25.0834 9.85229C23.1438 8.14093 20.6338 7.05707 17.8386 7.05707C11.8489 7.05707 7 11.9059 7 17.8386C7 23.8284 11.8489 28.6772 17.8386 28.6772C20.6338 28.6202 23.1438 27.5363 25.0834 25.825Z"
        fill="#454444"
      />
      <path
        d="M25.083 25.825C27.2507 23.8284 28.6198 20.9761 28.6198 17.8386C28.6198 14.6441 27.2507 11.7918 25.083 9.85229"
        fill="#4F4F4F"
      />
      <path
        d="M25.083 25.825C27.2507 23.8284 28.6198 20.9761 28.6198 17.8386C28.6198 14.6441 27.2507 11.7918 25.083 9.85229"
        stroke="#454444"
        stroke-width="0.3097"
      />
      <path
        d="M32.3282 7C29.533 7 26.9659 8.08386 25.0834 9.79522C24.6841 10.1375 24.3419 10.5368 23.9996 10.9361H26.1673C26.4525 11.2784 26.7378 11.6777 26.9659 12.077H23.1439C22.9157 12.4193 22.6875 12.8186 22.5164 13.2179H27.5934C27.7646 13.6173 27.9357 13.9595 28.0498 14.3588H22.06C21.946 14.7582 21.8319 15.1004 21.7178 15.4998H28.335C28.5062 16.2413 28.5632 16.9829 28.5632 17.7816C28.5632 18.9795 28.3921 20.1204 27.9928 21.2043H22.003C22.1171 21.6036 22.2882 22.0029 22.4594 22.3452H27.5364C27.3653 22.7445 27.1371 23.1438 26.9089 23.4861H23.0869C23.315 23.8854 23.6003 24.2847 23.8855 24.627H26.0532C25.7109 25.0263 25.3687 25.4256 24.9693 25.7679C26.9089 27.4793 29.4189 28.5631 32.2141 28.5631C38.2039 28.5631 42.9957 23.7143 42.9957 17.7245C43.1668 11.8489 38.318 7 32.3282 7Z"
        fill="#ACACAC"
      />
      <path
        d="M16.47 20.4627H15.1009L15.8995 16.2413L14.0741 20.4627H12.8761L12.648 16.2984L11.8493 20.4627H10.6514L11.6782 14.9863H13.7889L13.9029 18.409L15.3861 14.9863H17.5538L16.47 20.4627Z"
        fill="white"
      />
      <path
        d="M31.5292 20.4627C31.1869 20.5768 30.8447 20.6338 30.5594 20.6338C29.8749 20.6338 29.4756 20.2916 29.4756 19.6641C29.4756 19.55 29.4756 19.3788 29.5326 19.2648L29.5897 18.8084L29.6467 18.4661L30.2742 15.0434H31.5863L31.4151 16.0702H32.0997L31.9285 17.1541H31.244L30.9017 19.0366C30.9017 19.0936 30.9017 19.2077 30.9017 19.2077C30.9017 19.4359 31.0728 19.55 31.3581 19.55C31.5292 19.55 31.6433 19.55 31.7004 19.4929L31.5292 20.4627Z"
        fill="white"
      />
      <path
        d="M35.409 16.0702C35.295 16.0132 35.2949 16.0132 35.2379 16.0132C35.1809 16.0132 35.1238 16.0132 35.1238 16.0132C35.0668 16.0132 35.0097 16.0132 34.9527 16.0132C34.4963 16.0132 34.2111 16.1843 33.7547 16.7548L33.8688 16.0702H32.785L31.9863 20.5198H33.3554C33.8118 17.7816 34.0399 17.3252 34.6674 17.3252C34.7245 17.3252 34.7815 17.3252 34.8386 17.3252L35.0097 17.3823L35.409 16.0702Z"
        fill="white"
      />
      <path
        d="M26.1677 17.4393C26.1677 18.0098 26.51 18.4091 27.1945 18.6943C27.765 18.9225 27.822 18.9795 27.822 19.2077C27.822 19.4929 27.5938 19.607 27.0234 19.607C26.6241 19.607 26.2247 19.55 25.8254 19.4359L25.6543 20.4627H25.7113L25.9395 20.5197C25.9966 20.5197 26.1677 20.5768 26.2818 20.5768C26.6241 20.5768 26.8522 20.6338 27.0234 20.6338C28.4495 20.6338 29.1341 20.1775 29.1341 19.1507C29.1341 18.5232 28.8488 18.1809 28.1643 17.8957C27.5938 17.6675 27.5368 17.6104 27.5368 17.3822C27.5368 17.1541 27.765 17.04 28.2213 17.04C28.5066 17.04 28.8488 17.04 29.1911 17.097L29.3622 16.0702C29.02 16.0132 28.5066 15.9561 28.1643 15.9561C26.6811 15.9561 26.1677 16.6407 26.1677 17.4393Z"
        fill="white"
      />
      <path
        d="M19.8925 18.5232C19.7213 18.5232 19.6643 18.5232 19.6072 18.5232C18.8656 18.5232 18.4663 18.7513 18.4663 19.2077C18.4663 19.4929 18.6375 19.6641 18.9227 19.6641C19.4361 19.607 19.8354 19.1507 19.8925 18.5232ZM20.8622 20.4627H19.7213L19.7784 20.0063C19.4361 20.3486 18.9798 20.5197 18.3523 20.5197C17.6107 20.5197 17.1543 20.0063 17.1543 19.3218C17.1543 18.2379 18.01 17.6104 19.4932 17.6104C19.6643 17.6104 19.8354 17.6104 20.0636 17.6675C20.1207 17.4963 20.1206 17.4393 20.1206 17.3822C20.1206 17.097 19.8925 16.9829 19.265 16.9829C18.8656 16.9829 18.4663 17.04 18.1811 17.097L18.01 17.1541L17.8959 17.2111L18.067 16.1843C18.7516 16.0132 19.1509 15.9561 19.6643 15.9561C20.8052 15.9561 21.4327 16.4125 21.4327 17.2682C21.4327 17.4963 21.4327 17.6675 21.3186 18.1238L21.0334 19.7211L20.9763 20.0063L20.9193 20.2345V20.4057L20.8622 20.4627Z"
        fill="white"
      />
      <path
        d="M24.5699 17.7245C24.5699 17.6104 24.5699 17.5534 24.5699 17.4963C24.5699 17.154 24.3417 16.9259 23.9424 16.9259C23.486 16.9259 23.2008 17.2111 23.0867 17.7245H24.5699ZM25.2544 20.4056C24.7981 20.5197 24.3417 20.5768 23.8853 20.5768C22.4022 20.5768 21.6035 19.8922 21.6035 18.5802C21.6035 17.04 22.5733 15.899 23.9424 15.899C25.0262 15.899 25.7678 16.5265 25.7678 17.4963C25.7678 17.8386 25.7108 18.1238 25.5967 18.5802H22.9156C22.9156 18.6372 22.9156 18.6943 22.9156 18.6943C22.9156 19.2077 23.3149 19.4929 24.1135 19.4929C24.5699 19.4929 25.0262 19.3788 25.5396 19.2077L25.2544 20.4056Z"
        fill="white"
      />
      <path
        d="M38.2041 17.8957C38.2041 17.4393 38.033 16.9829 37.4625 16.9829C36.778 16.9829 36.3787 17.7816 36.3787 18.5232C36.3787 19.1507 36.6639 19.55 37.1773 19.55C37.4625 19.55 38.09 19.1507 38.2041 18.4661C38.2041 18.295 38.2041 18.0668 38.2041 17.8957ZM39.6303 18.4661C39.4021 19.9493 38.3753 20.6338 37.0062 20.6338C35.466 20.6338 34.8955 19.7211 34.8955 18.5802C34.8955 16.9829 35.9223 15.9561 37.5196 15.9561C38.9457 15.9561 39.6303 16.8118 39.6303 17.9527C39.6873 18.1809 39.6873 18.1809 39.6303 18.4661Z"
        fill="white"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="none"
        height="35"
        rx="7.5"
        stroke="#808082"
        width="49"
        x="0.5"
        y="0.5"
      />
      <path
        clip-rule="evenodd"
        d="M19.3998 17.2982C18.4338 16.2038 17.8477 14.7662 17.8477 13.1917C17.8477 11.6172 18.4338 10.1796 19.3998 9.08521C20.3659 10.1796 20.952 11.6172 20.952 13.1917C20.952 14.7662 20.3659 16.2038 19.3998 17.2982Z"
        fill="white"
        fill-rule="evenodd"
      />
      <path
        d="M43.9996 17.0522C43.9996 23.1168 39.1813 28.1044 33.2 28.1044C27.2741 28.1044 22.4004 23.1168 22.4004 17.0522C22.4004 10.9877 27.2187 6 33.1446 6C39.1813 6 43.9996 10.9877 43.9996 17.0522Z"
        fill="#ACACAC"
      />
      <path
        d="M29.5438 17.1089C29.5438 16.3154 29.433 15.5219 29.3222 14.7851H22.6764C22.7317 14.3883 22.8425 14.0483 22.9533 13.5948H28.8792C28.7684 13.1981 28.6023 12.8014 28.4361 12.4046H23.3963C23.5625 12.0079 23.784 11.6678 24.0055 11.2144H27.8269C27.6054 10.8176 27.3285 10.4209 26.9962 10.0241H24.8916C25.2239 9.62739 25.5562 9.28732 25.9993 8.89058C24.1163 7.07688 21.5687 6 18.7442 6C12.8737 6.17003 8 10.9877 8 17.0522C8 23.1168 12.8183 28.1044 18.7996 28.1044C21.6241 28.1044 24.1163 26.9709 26.0547 25.2138C26.4424 24.8738 26.7747 24.477 27.1623 24.0236H24.947C24.6701 23.6835 24.3932 23.2868 24.1717 22.89H27.9377C28.1592 22.55 28.3807 22.1532 28.5469 21.6998H23.5071C23.3409 21.3597 23.1748 20.963 23.064 20.5096H28.99C29.3222 19.4894 29.5438 18.3558 29.5438 17.1089Z"
        fill="#4F4F4F"
      />
      <path
        d="M18.2238 19.9165H16.9947L17.7117 16.1267L16.0728 19.9165H14.9973L14.7925 16.1779L14.0755 19.9165H13L13.9218 15H15.8168L15.9192 18.0728L17.2507 15H19.1969L18.2238 19.9165Z"
        fill="white"
      />
      <path
        d="M31.7431 19.9165C31.4358 20.0189 31.1285 20.0701 30.8725 20.0701C30.2579 20.0701 29.8994 19.7628 29.8994 19.1995C29.8994 19.097 29.8994 18.9434 29.9506 18.841L30.0018 18.4313L30.053 18.124L30.6164 15.0511H31.7943L31.6407 15.973H32.2552L32.1016 16.9461H31.487L31.1798 18.6361C31.1798 18.6873 31.1798 18.7898 31.1798 18.7898C31.1798 18.9946 31.3334 19.097 31.5895 19.097C31.7431 19.097 31.8455 19.097 31.8967 19.0458L31.7431 19.9165Z"
        fill="white"
      />
      <path
        d="M35.2271 15.9731C35.1247 15.9219 35.1247 15.9219 35.0735 15.9219C35.0223 15.9219 34.9711 15.9219 34.9711 15.9219C34.9198 15.9219 34.8686 15.9219 34.8174 15.9219C34.4077 15.9219 34.1516 16.0755 33.7419 16.5876L33.8443 15.9731H32.8713L32.1543 19.9678H33.3834C33.7931 17.5095 33.998 17.0998 34.5613 17.0998C34.6125 17.0998 34.6638 17.0998 34.715 17.0998L34.8686 17.151L35.2271 15.9731Z"
        fill="white"
      />
      <path
        d="M26.9306 17.2022C26.9306 17.7143 27.2379 18.0728 27.8525 18.3289C28.3646 18.5337 28.4158 18.5849 28.4158 18.7898C28.4158 19.0459 28.211 19.1483 27.6989 19.1483C27.3404 19.1483 26.9819 19.0971 26.6234 18.9946L26.4697 19.9165H26.5209L26.7258 19.9677C26.777 19.9677 26.9307 20.0189 27.0331 20.0189C27.3404 20.0189 27.5452 20.0701 27.6989 20.0701C28.9792 20.0701 29.5938 19.6604 29.5938 18.7386C29.5938 18.1752 29.3377 17.8679 28.7231 17.6119C28.211 17.407 28.1598 17.3558 28.1598 17.151C28.1598 16.9461 28.3646 16.8437 28.7743 16.8437C29.0304 16.8437 29.3377 16.8437 29.645 16.8949L29.7986 15.973C29.4913 15.9218 29.0304 15.8706 28.7231 15.8706C27.3916 15.8706 26.9306 16.4852 26.9306 17.2022Z"
        fill="white"
      />
      <path
        d="M21.2961 18.1752C21.1425 18.1752 21.0913 18.1752 21.0401 18.1752C20.3743 18.1752 20.0158 18.3801 20.0158 18.7898C20.0158 19.0459 20.1695 19.1995 20.4255 19.1995C20.8864 19.1483 21.2449 18.7386 21.2961 18.1752ZM22.1668 19.9165H21.1425L21.1937 19.5068C20.8864 19.8141 20.4767 19.9677 19.9134 19.9677C19.2476 19.9677 18.8379 19.5068 18.8379 18.8922C18.8379 17.9192 19.6061 17.3558 20.9377 17.3558C21.0913 17.3558 21.2449 17.3558 21.4498 17.407C21.501 17.2534 21.501 17.2022 21.501 17.151C21.501 16.8949 21.2961 16.7925 20.7328 16.7925C20.3743 16.7925 20.0158 16.8437 19.7597 16.8949L19.6061 16.9461L19.5037 16.9973L19.6573 16.0755C20.2719 15.9218 20.6304 15.8706 21.0913 15.8706C22.1156 15.8706 22.6789 16.2803 22.6789 17.0485C22.6789 17.2534 22.6789 17.407 22.5765 17.8167L22.3204 19.2507L22.2692 19.5068L22.218 19.7116V19.8653L22.1668 19.9165Z"
        fill="white"
      />
      <path
        d="M25.4961 17.4583C25.4961 17.3559 25.4961 17.3047 25.4961 17.2534C25.4961 16.9462 25.2913 16.7413 24.9328 16.7413C24.5231 16.7413 24.267 16.9974 24.1646 17.4583H25.4961ZM26.1107 19.8654C25.701 19.9678 25.2913 20.019 24.8816 20.019C23.55 20.019 22.833 19.4044 22.833 18.2265C22.833 16.8437 23.7036 15.8195 24.9328 15.8195C25.9058 15.8195 26.5716 16.3828 26.5716 17.2534C26.5716 17.5607 26.5204 17.8168 26.418 18.2265H24.0109C24.0109 18.2777 24.0109 18.3289 24.0109 18.3289C24.0109 18.7899 24.3694 19.0459 25.0864 19.0459C25.4961 19.0459 25.9058 18.9435 26.3668 18.7899L26.1107 19.8654Z"
        fill="white"
      />
      <path
        d="M37.736 17.6119C37.736 17.2022 37.5824 16.7925 37.0702 16.7925C36.4557 16.7925 36.0972 17.5094 36.0972 18.1752C36.0972 18.7386 36.3533 19.0971 36.8142 19.0971C37.0702 19.0971 37.6336 18.7386 37.736 18.124C37.736 17.9704 37.736 17.7655 37.736 17.6119ZM39.0164 18.124C38.8115 19.4556 37.8897 20.0701 36.6605 20.0701C35.2778 20.0701 34.7656 19.2507 34.7656 18.2264C34.7656 16.7925 35.6875 15.8706 37.1214 15.8706C38.4018 15.8706 39.0164 16.6388 39.0164 17.6631C39.0676 17.8679 39.0676 17.8679 39.0164 18.124Z"
        fill="white"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
