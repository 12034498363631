<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_439_2612)">
        <rect
          fill="#ACACAC"
          height="27"
          width="48"
          x="1"
          y="4"
        />
        <path
          d="M5 0C2.24219 0 0 2.24219 0 5V31C0 33.7578 2.24219 36 5 36H45C47.7578 36 50 33.7578 50 31V5C50 2.24219 47.7578 0 45 0H5ZM29.6875 12.4062C31.0508 12.4062 32.4688 12.9688 32.4688 12.9688L31.9688 15.4062C31.9688 15.4062 30.8906 14.6875 29.9375 14.6875C28.4688 14.6875 27.9375 15.168 27.9375 15.8125C27.9375 17.0742 32.0312 17.2969 32.0312 20.125C32.0312 22.4766 29.1133 24.0938 27 24.0938C24.8867 24.0938 23.7812 23.4688 23.7812 23.4688L24.3125 21.0938C24.3125 21.0938 25.418 21.75 27.125 21.75C28.8281 21.75 29.0625 21.0234 29.0625 20.7188C29.0625 18.9141 25 19.418 25 15.9062C25 13.9648 26.5859 12.4062 29.6875 12.4062ZM16.4688 12.625H19.625L15.125 23.75H11.875L9.5 14.75C9.5 14.75 11.9961 16.0234 13.5312 19.4688C13.5977 19.8867 13.7812 20.5625 13.7812 20.5625L16.4688 12.625ZM20.7812 12.625H23.7812L22 23.75H19.0312L20.7812 12.625ZM36.8125 12.625H39.9062L42.1875 23.75H39.5L39.1875 22.1562H35.5L34.9062 23.75H31.9688L36.8125 12.625ZM6.25 12.6562H10.8125C11.9766 12.6562 12.4062 13.75 12.4062 13.75L13.4062 18.8125C12.0547 14.4531 6.25 12.6562 6.25 12.6562ZM37.9375 15.8438L36.3125 20.0312H38.75L37.9375 15.8438Z"
          fill="#2F2F2F"
        />
      </g>
      <defs>
        <clipPath id="clip0_439_2612">
          <rect
            fill="white"
            height="36"
            width="50"
          />
        </clipPath>
      </defs>
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="none"
        height="35"
        rx="7.5"
        stroke="#808082"
        width="49"
        x="0.5"
        y="0.5"
      />
      <path
        d="M28.8494 12C30.2948 12 31.7982 12.5893 31.7982 12.5893L31.2681 15.1429C31.2681 15.1429 30.125 14.3899 29.1145 14.3899C27.5572 14.3899 26.994 14.8932 26.994 15.5685C26.994 16.8903 31.3343 17.1235 31.3343 20.0863C31.3343 22.5499 28.2406 24.244 26 24.244C23.7594 24.244 22.5874 23.5893 22.5874 23.5893L23.1506 21.1012C23.1506 21.1012 24.3227 21.7887 26.1325 21.7887C27.9383 21.7887 28.1867 21.0275 28.1867 20.7083C28.1867 18.8177 23.8795 19.3456 23.8795 15.6667C23.8795 13.6328 25.561 12 28.8494 12ZM14.8343 12.2292H18.1807L13.4096 23.8839H9.96386L7.44578 14.4554C7.44578 14.4554 10.0922 15.7894 11.7199 19.3988C11.7903 19.8367 11.9849 20.5446 11.9849 20.5446L14.8343 12.2292ZM19.4066 12.2292H22.5874L20.6988 23.8839H17.5512L19.4066 12.2292ZM36.4036 12.2292H39.6837L42.1024 23.8839H39.253L38.9217 22.2143H35.012L34.3825 23.8839H31.2681L36.4036 12.2292ZM4 12.2619H8.83735C10.0715 12.2619 10.5271 13.4077 10.5271 13.4077L11.5873 18.7113C10.1544 14.1443 4 12.2619 4 12.2619ZM37.5964 15.6012L35.8735 19.9881H38.4578L37.5964 15.6012Z"
        fill="#808082"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
