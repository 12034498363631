<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M0 5C0 2.24219 2.24219 0 5 0H45C47.7578 0 50 2.24219 50 5V31C50 33.7578 47.7578 36 45 36H5C2.24219 36 0 33.7578 0 31V5Z"
        fill="#2F2F2F"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M14.5852 7C12.6258 7 11.0183 8.63131 11.0183 10.6663V19.712C12.0179 20.2153 13.0562 20.5382 14.0956 20.5382C15.3309 20.5382 15.9969 19.7729 15.9969 18.7255V14.4543H19.0549V18.7045C19.0549 20.3568 18.0553 21.7061 14.6648 21.7061C12.6065 21.7061 11 21.2427 11 21.2427V28.958H16.2315C18.1919 28.958 19.7984 27.3267 19.7984 25.2917V7H14.5852ZM34.3025 7C32.342 7 30.7356 8.63131 30.7356 10.6663V14.4742H35.7723C35.8896 14.4742 36.0273 14.4742 36.1252 14.4941C37.2615 14.5549 38.1051 15.1588 38.1051 16.2061C38.1051 17.0323 37.5369 17.7368 36.4782 17.8783V17.9182C37.6348 17.9989 38.5161 18.6636 38.5161 19.691C38.5161 20.7992 37.5359 21.5247 36.2425 21.5247H30.7151V28.979H35.9477C37.9081 28.979 39.5146 27.3477 39.5146 25.3127V7H34.3025ZM32.4798 20.3568H34.5381C34.5677 20.3568 34.6167 20.3518 34.6656 20.3469C34.7146 20.3419 34.7636 20.3369 34.7931 20.3369C35.1848 20.2562 35.5184 19.8934 35.5184 19.3902C35.5184 18.887 35.1848 18.5441 34.7931 18.4435C34.734 18.4236 34.6167 18.4236 34.5381 18.4236H32.4798V20.3568ZM34.5371 15.5823C34.9287 15.6421 35.2623 15.965 35.2623 16.4483V16.4494C35.2623 16.9327 34.9287 17.2546 34.5371 17.3154C34.5177 17.3353 34.4004 17.3353 34.3412 17.3353H32.4798V15.5624H34.3412C34.3732 15.5624 34.4176 15.5685 34.4575 15.5739C34.4901 15.5783 34.5196 15.5823 34.5371 15.5823ZM20.8762 10.6663C20.8762 8.63131 22.4838 7 24.4442 7H29.6757V25.3337C29.6757 27.3687 28.0693 29 26.1088 29H20.8762V20.5393C21.7779 21.3246 23.3456 21.8278 25.8742 21.7072C27.2461 21.6464 28.6772 21.2637 28.6772 21.2637V19.712C27.952 20.0748 27.089 20.4376 25.9721 20.5183C24.0515 20.6798 22.8948 19.6921 22.8948 18C22.8948 16.3079 24.0515 15.3401 25.9721 15.4817C27.0901 15.5624 27.952 15.9053 28.6772 16.288V14.7363C28.6772 14.7363 27.2268 14.3536 25.8742 14.2928C23.3456 14.1711 21.7779 14.6754 20.8762 15.4607V10.6663Z"
        fill="#ACACAC"
        fill-rule="evenodd"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="none"
        height="35"
        rx="5.5"
        stroke="#8A8A8A"
        width="49"
        x="0.5"
        y="0.5"
      />
      <path
        clip-rule="evenodd"
        d="M13.6462 7C11.6535 7 10.0186 8.63449 10.0186 10.6734V19.7368C11.0352 20.241 12.0912 20.5646 13.1483 20.5646C14.4046 20.5646 15.0819 19.7978 15.0819 18.7484V14.4688H18.1919V18.7273C18.1919 20.3828 17.1753 21.7348 13.7272 21.7348C11.6338 21.7348 10 21.2705 10 21.2705V29.0007H15.3205C17.3143 29.0007 18.9481 27.3663 18.9481 25.3273V7H13.6462ZM33.6994 7C31.7056 7 30.0718 8.63449 30.0718 10.6734V14.4887H35.1942C35.3135 14.4887 35.4535 14.4887 35.5531 14.5087C36.7087 14.5696 37.5666 15.1746 37.5666 16.224C37.5666 17.0518 36.9888 17.7577 35.9121 17.8995V17.9394C37.0884 18.0203 37.9847 18.6863 37.9847 19.7157C37.9847 20.8261 36.9878 21.553 35.6724 21.553H30.051V29.0218H35.3726C37.3664 29.0218 39.0002 27.3873 39.0002 25.3484V7H33.6994ZM31.8456 20.3828H33.939C33.9691 20.3828 34.0189 20.3779 34.0687 20.3729C34.1185 20.3679 34.1683 20.3629 34.1984 20.3629C34.5967 20.282 34.9359 19.9185 34.9359 19.4143C34.9359 18.9101 34.5967 18.5666 34.1984 18.4658C34.1382 18.4458 34.0189 18.4458 33.939 18.4458H31.8456V20.3828ZM33.9379 15.5991C34.3363 15.6589 34.6755 15.9825 34.6755 16.4667V16.4678C34.6755 16.9521 34.3363 17.2745 33.9379 17.3355C33.9182 17.3554 33.799 17.3554 33.7388 17.3554H31.8456V15.5791H33.7388C33.7713 15.5791 33.8164 15.5852 33.857 15.5906L33.857 15.5906L33.857 15.5906C33.8902 15.595 33.9202 15.5991 33.9379 15.5991ZM20.0447 10.6734C20.0447 8.63449 21.6796 7 23.6734 7H28.9939V25.3694C28.9939 27.4084 27.3601 29.0428 25.3663 29.0428H20.0447V20.5657C20.9617 21.3525 22.5561 21.8566 25.1277 21.7359C26.5229 21.6749 27.9784 21.2915 27.9784 21.2915V19.7368C27.2408 20.1003 26.3632 20.4637 25.2273 20.5446C23.274 20.7064 22.0976 19.7169 22.0976 18.0214C22.0976 16.326 23.274 15.3564 25.2273 15.4982C26.3643 15.5791 27.2408 15.9226 27.9784 16.306V14.7513C27.9784 14.7513 26.5032 14.3679 25.1277 14.307C22.5561 14.1851 20.9617 14.6904 20.0447 15.4772V10.6734Z"
        fill="#ACACAC"
        fill-rule="evenodd"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
