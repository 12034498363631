<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#2F2F2F"
        height="36"
        rx="6"
        width="50"
      />
      <path
        clip-rule="evenodd"
        d="M25 22.2917C26.2447 20.8814 27 19.0289 27 17C27 14.9711 26.2447 13.1186 25 11.7084C23.7553 13.1186 23 14.9711 23 17C23 19.0289 23.7553 20.8814 25 22.2917Z"
        fill="white"
        fill-rule="evenodd"
      />
      <path
        d="M43.0002 17.7999C43.0002 23.726 38.1818 28.5998 32.2003 28.5998C26.2742 28.5998 21.4004 23.726 21.4004 17.7999C21.4004 11.8738 26.2188 7 32.1449 7C38.1818 7 43.0002 11.8738 43.0002 17.7999Z"
        fill="#ACACAC"
      />
      <path
        d="M28.5444 17.8553C28.5444 17.0799 28.4336 16.3045 28.3229 15.5845H21.6768C21.7322 15.1968 21.8429 14.8645 21.9537 14.4215H27.8798C27.769 14.0338 27.6029 13.6461 27.4367 13.2584H22.3968C22.5629 12.8707 22.7845 12.5384 23.006 12.0953H26.8275C26.606 11.7076 26.329 11.32 25.9967 10.9323H23.8921C24.2244 10.5446 24.5567 10.2123 24.9998 9.82459C23.1168 8.0523 20.5691 7 17.7445 7C11.8738 7.16615 7 11.8738 7 17.7999C7 23.726 11.8184 28.5998 17.7999 28.5998C20.6245 28.5998 23.1168 27.4921 25.0552 25.7752C25.4429 25.4429 25.7752 25.0552 26.1629 24.6121H23.9475C23.6706 24.2798 23.3937 23.8921 23.1721 23.5045H26.9383C27.1598 23.1722 27.3813 22.7845 27.5475 22.3414H22.5075C22.3414 22.0091 22.1752 21.6214 22.0645 21.1783H27.9906C28.3229 20.1814 28.5444 19.0737 28.5444 17.8553Z"
        fill="#4F4F4F"
      />
      <path
        d="M21.6215 20.5691L21.7876 19.5722C21.7323 19.5722 21.6215 19.6276 21.5107 19.6276C21.123 19.6276 21.0676 19.4061 21.123 19.2953L21.4553 17.3015H22.0646L22.2307 16.1938H21.6769L21.7876 15.5292H20.6246C20.6246 15.5292 19.96 19.2953 19.96 19.7384C19.96 20.403 20.3476 20.7353 20.9015 20.7353C21.2338 20.7353 21.5107 20.6245 21.6215 20.5691Z"
        fill="white"
      />
      <path
        d="M22.0078 18.7414C22.0078 20.3476 23.1155 20.7352 24.0016 20.7352C24.8324 20.7352 25.2201 20.5691 25.2201 20.5691L25.4416 19.4614C25.4416 19.4614 24.8324 19.7383 24.2232 19.7383C22.9493 19.7383 23.1709 18.7968 23.1709 18.7968H25.497C25.497 18.7968 25.6632 18.0768 25.6632 17.7445C25.6632 17.0245 25.2755 16.083 24.0016 16.083C22.894 16.0276 22.0078 17.3014 22.0078 18.7414ZM24.0016 17.0799C24.6109 17.0799 24.5001 17.7999 24.5001 17.8553H23.2263C23.2816 17.7999 23.3924 17.0799 24.0016 17.0799Z"
        fill="white"
      />
      <path
        d="M31.2585 20.5691L31.48 19.2953C31.48 19.2953 30.9262 19.5722 30.4831 19.5722C29.7077 19.5722 29.32 18.963 29.32 18.243C29.32 16.8584 29.9846 16.1384 30.8154 16.1384C31.3692 16.1384 31.8677 16.4707 31.8677 16.4707L32.0338 15.2523C32.0338 15.2523 31.3692 14.9753 30.7046 14.9753C29.3754 14.9753 28.1016 16.1384 28.1016 18.2984C28.1016 19.7384 28.7662 20.6799 30.1508 20.6799C30.6492 20.7353 31.2585 20.5691 31.2585 20.5691Z"
        fill="white"
      />
      <path
        d="M15.3641 16.0276C14.5887 16.0276 13.9795 16.2492 13.9795 16.2492L13.8133 17.2461C13.8133 17.2461 14.3118 17.0246 15.0872 17.0246C15.4748 17.0246 15.8072 17.0799 15.8072 17.4123C15.8072 17.6338 15.7518 17.6892 15.7518 17.6892H15.2533C14.2564 17.6892 13.2041 18.0769 13.2041 19.4061C13.2041 20.4584 13.8687 20.6799 14.3118 20.6799C15.0872 20.6799 15.4748 20.1815 15.5302 20.1815L15.4748 20.6245H16.4164L16.8594 17.4676C16.8594 16.083 15.7518 16.0276 15.3641 16.0276ZM15.5856 18.5753C15.5856 18.7415 15.4748 19.683 14.8102 19.683C14.4779 19.683 14.3672 19.4061 14.3672 19.2399C14.3672 18.963 14.5333 18.5753 15.4195 18.5753C15.5302 18.5753 15.5856 18.5753 15.5856 18.5753Z"
        fill="white"
      />
      <path
        d="M17.9657 20.6799C18.2426 20.6799 19.6826 20.7353 19.6826 19.1845C19.6826 17.7445 18.298 18.0214 18.298 17.4676C18.298 17.1907 18.5195 17.0799 18.9072 17.0799C19.0733 17.0799 19.6826 17.1353 19.6826 17.1353L19.8487 16.083C19.8487 16.083 19.461 15.9722 18.741 15.9722C17.9103 15.9722 17.0241 16.3045 17.0241 17.4676C17.0241 18.7968 18.4641 18.686 18.4641 19.1845C18.4641 19.5168 18.0764 19.5722 17.7995 19.5722C17.301 19.5722 16.7472 19.406 16.7472 19.406L16.5811 20.4583C16.6918 20.5691 17.0241 20.6799 17.9657 20.6799Z"
        fill="white"
      />
      <path
        d="M40.7833 15.1414L40.5617 16.6922C40.5617 16.6922 40.1187 16.1383 39.4541 16.1383C38.4018 16.1383 37.5156 17.4122 37.5156 18.9075C37.5156 19.8491 37.9587 20.7906 38.9556 20.7906C39.6202 20.7906 40.0633 20.3475 40.0633 20.3475L40.0079 20.7352H41.171L42.0017 15.2522L40.7833 15.1414ZM40.2294 18.1322C40.2294 18.7414 39.9525 19.5721 39.2879 19.5721C38.9002 19.5721 38.6787 19.2398 38.6787 18.6306C38.6787 17.6891 39.0664 17.1352 39.6202 17.1352C40.0079 17.1906 40.2294 17.4676 40.2294 18.1322Z"
        fill="white"
      />
      <path
        d="M9.10475 20.6244L9.76936 16.526L9.88013 20.6244H10.6555L12.1509 16.526L11.5417 20.6244H12.7601L13.7016 15.1414H11.8186L10.6555 18.5198L10.6001 15.1414H8.9386L7.99707 20.6244H9.10475Z"
        fill="white"
      />
      <path
        d="M26.8275 20.6245C27.1598 18.7414 27.2152 17.1907 28.046 17.4676C28.1568 16.7476 28.3229 16.4153 28.4337 16.1384H28.2121C27.7137 16.1384 27.2706 16.803 27.2706 16.803L27.3814 16.1938H26.2737L25.5537 20.6245H26.8275Z"
        fill="white"
      />
      <path
        d="M33.9178 16.0276C33.1424 16.0276 32.5332 16.2492 32.5332 16.2492L32.367 17.2461C32.367 17.2461 32.8655 17.0246 33.6409 17.0246C34.0286 17.0246 34.3609 17.0799 34.3609 17.4123C34.3609 17.6338 34.3055 17.6892 34.3055 17.6892H33.807C32.8101 17.6892 31.7578 18.0769 31.7578 19.4061C31.7578 20.4584 32.4224 20.6799 32.8655 20.6799C33.6409 20.6799 34.0286 20.1815 34.0839 20.1815L34.0286 20.6245H35.0809L35.5239 17.4676C35.5239 16.083 34.3055 16.0276 33.9178 16.0276ZM34.1947 18.5753C34.1947 18.7415 34.0839 19.683 33.4193 19.683C33.087 19.683 32.9763 19.4061 32.9763 19.2399C32.9763 18.963 33.1424 18.5753 34.0286 18.5753C34.1393 18.5753 34.1393 18.5753 34.1947 18.5753Z"
        fill="white"
      />
      <path
        d="M36.4086 20.6245C36.7409 18.7414 36.7963 17.1907 37.627 17.4676C37.7378 16.7476 37.904 16.4153 38.0147 16.1384H37.7932C37.2947 16.1384 36.8517 16.803 36.8517 16.803L36.9624 16.1938H35.8548L35.1348 20.6245H36.4086Z"
        fill="white"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="none"
        height="35"
        rx="7.5"
        stroke="#808082"
        width="49"
        x="0.5"
        y="0.5"
      />
      <path
        clip-rule="evenodd"
        d="M19.3998 17.2982C18.4338 16.2038 17.8477 14.7662 17.8477 13.1917C17.8477 11.6172 18.4338 10.1796 19.3998 9.08521C20.3659 10.1796 20.952 11.6172 20.952 13.1917C20.952 14.7662 20.3659 16.2038 19.3998 17.2982Z"
        fill="white"
        fill-rule="evenodd"
      />
      <path
        d="M43.9996 17.0522C43.9996 23.1168 39.1813 28.1044 33.2 28.1044C27.2741 28.1044 22.4004 23.1168 22.4004 17.0522C22.4004 10.9877 27.2187 6 33.1446 6C39.1813 6 43.9996 10.9877 43.9996 17.0522Z"
        fill="#ACACAC"
      />
      <path
        d="M29.5438 17.1089C29.5438 16.3154 29.433 15.5219 29.3222 14.7851H22.6764C22.7317 14.3883 22.8425 14.0483 22.9533 13.5948H28.8792C28.7684 13.1981 28.6023 12.8014 28.4361 12.4046H23.3963C23.5625 12.0079 23.784 11.6678 24.0055 11.2144H27.8269C27.6054 10.8176 27.3285 10.4209 26.9962 10.0241H24.8916C25.2239 9.62739 25.5562 9.28732 25.9993 8.89058C24.1163 7.07688 21.5687 6 18.7442 6C12.8737 6.17003 8 10.9877 8 17.0522C8 23.1168 12.8183 28.1044 18.7996 28.1044C21.6241 28.1044 24.1163 26.9709 26.0547 25.2138C26.4424 24.8738 26.7747 24.477 27.1623 24.0236H24.947C24.6701 23.6835 24.3932 23.2868 24.1717 22.89H27.9377C28.1592 22.55 28.3807 22.1532 28.5469 21.6998H23.5071C23.3409 21.3597 23.1748 20.963 23.064 20.5096H28.99C29.3222 19.4894 29.5438 18.3558 29.5438 17.1089Z"
        fill="#4F4F4F"
      />
      <path
        d="M22.6214 19.8872L22.7876 18.867C22.7322 18.867 22.6214 18.9237 22.5107 18.9237C22.123 18.9237 22.0676 18.697 22.123 18.5836L22.4553 16.5432H23.0645L23.2306 15.4096H22.6768L22.7876 14.7295H21.6246C21.6246 14.7295 20.96 18.5836 20.96 19.037C20.96 19.7172 21.3476 20.0572 21.9015 20.0572C22.2338 20.0572 22.5107 19.9439 22.6214 19.8872Z"
        fill="white"
      />
      <path
        d="M23.0078 18.016C23.0078 19.6597 24.1155 20.0564 25.0016 20.0564C25.8323 20.0564 26.22 19.8864 26.22 19.8864L26.4415 18.7528C26.4415 18.7528 25.8323 19.0362 25.2231 19.0362C23.9493 19.0362 24.1708 18.0727 24.1708 18.0727H26.4969C26.4969 18.0727 26.6631 17.3359 26.6631 16.9958C26.6631 16.259 26.2754 15.2955 25.0016 15.2955C23.8939 15.2388 23.0078 16.5424 23.0078 18.016ZM25.0016 16.3157C25.6108 16.3157 25.5 17.0525 25.5 17.1092H24.2262C24.2816 17.0525 24.3924 16.3157 25.0016 16.3157Z"
        fill="white"
      />
      <path
        d="M32.2574 19.8876L32.4789 18.584C32.4789 18.584 31.9251 18.8674 31.482 18.8674C30.7067 18.8674 30.319 18.2439 30.319 17.5071C30.319 16.0901 30.9836 15.3533 31.8143 15.3533C32.3682 15.3533 32.8666 15.6934 32.8666 15.6934L33.0327 14.4465C33.0327 14.4465 32.3682 14.1631 31.7036 14.1631C30.3744 14.1631 29.1006 15.3533 29.1006 17.5638C29.1006 19.0374 29.7652 20.0009 31.1497 20.0009C31.6482 20.0576 32.2574 19.8876 32.2574 19.8876Z"
        fill="white"
      />
      <path
        d="M16.363 15.2388C15.5877 15.2388 14.9785 15.4655 14.9785 15.4655L14.8123 16.4857C14.8123 16.4857 15.3108 16.259 16.0861 16.259C16.4738 16.259 16.8061 16.3157 16.8061 16.6557C16.8061 16.8824 16.7507 16.9391 16.7507 16.9391H16.2523C15.2554 16.9391 14.2031 17.3359 14.2031 18.6961C14.2031 19.773 14.8677 19.9997 15.3108 19.9997C16.0861 19.9997 16.4738 19.4896 16.5292 19.4896L16.4738 19.943H17.4153L17.8584 16.7124C17.8584 15.2954 16.7507 15.2388 16.363 15.2388ZM16.5846 17.846C16.5846 18.016 16.4738 18.9795 15.8092 18.9795C15.4769 18.9795 15.3662 18.6961 15.3662 18.5261C15.3662 18.2427 15.5323 17.846 16.4184 17.846C16.5292 17.846 16.5846 17.846 16.5846 17.846Z"
        fill="white"
      />
      <path
        d="M18.9646 20C19.2416 20 20.6815 20.0567 20.6815 18.4697C20.6815 16.9961 19.2969 17.2795 19.2969 16.7127C19.2969 16.4293 19.5185 16.3159 19.9061 16.3159C20.0723 16.3159 20.6815 16.3726 20.6815 16.3726L20.8476 15.2957C20.8476 15.2957 20.46 15.1824 19.74 15.1824C18.9093 15.1824 18.0231 15.5224 18.0231 16.7127C18.0231 18.073 19.4631 17.9596 19.4631 18.4697C19.4631 18.8098 19.0754 18.8664 18.7985 18.8664C18.3001 18.8664 17.7462 18.6964 17.7462 18.6964L17.5801 19.7733C17.6908 19.8866 18.0231 20 18.9646 20Z"
        fill="white"
      />
      <path
        d="M41.7812 14.3325L41.5597 15.9195C41.5597 15.9195 41.1166 15.3527 40.4521 15.3527C39.3998 15.3527 38.5137 16.6563 38.5137 18.1866C38.5137 19.1502 38.9567 20.1137 39.9536 20.1137C40.6182 20.1137 41.0613 19.6603 41.0613 19.6603L41.0059 20.057H42.1689L42.9997 14.4459L41.7812 14.3325ZM41.2274 17.3931C41.2274 18.0166 40.9505 18.8668 40.2859 18.8668C39.8982 18.8668 39.6767 18.5267 39.6767 17.9032C39.6767 16.9397 40.0644 16.3729 40.6182 16.3729C41.0059 16.4296 41.2274 16.713 41.2274 17.3931Z"
        fill="white"
      />
      <path
        d="M10.1047 19.9439L10.7693 15.7497L10.8801 19.9439H11.6554L13.1508 15.7497L12.5415 19.9439H13.76L14.7015 14.3328H12.8185L11.6554 17.7901L11.6 14.3328H9.93857L8.99707 19.9439H10.1047Z"
        fill="white"
      />
      <path
        d="M27.8265 19.9425C28.1588 18.0154 28.2142 16.4284 29.0449 16.7118C29.1557 15.975 29.3219 15.635 29.4326 15.3516H29.2111C28.7127 15.3516 28.2696 16.0317 28.2696 16.0317L28.3804 15.4082H27.2727L26.5527 19.9425H27.8265Z"
        fill="white"
      />
      <path
        d="M34.9177 15.2385C34.1424 15.2385 33.5332 15.4652 33.5332 15.4652L33.367 16.4854C33.367 16.4854 33.8655 16.2587 34.6408 16.2587C35.0285 16.2587 35.3608 16.3154 35.3608 16.6555C35.3608 16.8822 35.3054 16.9389 35.3054 16.9389H34.807C33.8101 16.9389 32.7578 17.3356 32.7578 18.6959C32.7578 19.7728 33.4224 19.9995 33.8655 19.9995C34.6408 19.9995 35.0285 19.4894 35.0839 19.4894L35.0285 19.9428H36.0808L36.5238 16.7122C36.5238 15.2952 35.3054 15.2385 34.9177 15.2385ZM35.1946 17.8457C35.1946 18.0157 35.0839 18.9793 34.4193 18.9793C34.087 18.9793 33.9762 18.6959 33.9762 18.5259C33.9762 18.2425 34.1424 17.8457 35.0285 17.8457C35.1393 17.8457 35.1393 17.8457 35.1946 17.8457Z"
        fill="white"
      />
      <path
        d="M37.4086 19.9425C37.7409 18.0154 37.7962 16.4284 38.627 16.7118C38.7377 15.975 38.9039 15.635 39.0147 15.3516H38.7931C38.2947 15.3516 37.8516 16.0317 37.8516 16.0317L37.9624 15.4082H36.8547L36.1348 19.9425H37.4086Z"
        fill="white"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
