<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#2F2F2F"
        height="36"
        rx="6"
        width="50"
      />
      <g clip-path="url(#clip0_439_2622)">
        <path
          d="M16.24 13.0022V13.0066C16.2353 13.0066 14.7553 13.0022 14.3606 14.3164C13.9988 15.5215 12.9791 18.8471 12.951 18.9387H12.669C12.669 18.9387 11.6259 15.5389 11.2594 14.3121C10.8647 12.9978 9.37994 13.0022 9.37994 13.0022H6V22.9978H9.3815V17.0612H9.66342L11.6369 22.9978H13.9847L15.9581 17.0656H16.24V22.9978H19.6216V13.0022H16.24ZM28.5929 13.0022C28.5929 13.0022 27.6015 13.0852 27.1364 14.0501L24.7416 18.9387H24.4597V13.0022H21.0782V22.9978H24.2717C24.2717 22.9978 25.3101 22.9105 25.7753 21.95L28.1231 17.0613H28.405V22.9978H31.7866V13.0022H28.5929ZM33.2901 17.5416V22.9978H36.6716V19.812H40.3351C41.9326 19.812 43.2843 18.8645 43.787 17.543H33.2901V17.5416Z"
          fill="#ACACAC"
        />
        <path
          d="M40.3365 13.0022H32.8154C33.1913 14.9059 34.7278 16.4326 36.7137 16.9303C37.1748 17.0463 37.6504 17.1051 38.128 17.105H43.9247C43.9764 16.878 43.9999 16.6465 43.9999 16.4064C43.9999 14.526 42.3601 13.0022 40.3365 13.0022Z"
          fill="#ACACAC"
        />
      </g>
      <defs>
        <clipPath id="clip0_439_2622">
          <rect
            fill="white"
            height="10"
            transform="translate(6 13)"
            width="38"
          />
        </clipPath>
      </defs>
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      fill="none"
      height="36"
      viewBox="0 0 50 36"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="none"
        height="35"
        rx="7.5"
        stroke="#808082"
        width="49"
        x="0.5"
        y="0.5"
      />
      <g clip-path="url(#clip0_793_7417)">
        <path
          d="M16.0485 13.0024V13.0068C16.0434 13.0068 14.4465 13.0024 14.0206 14.3167C13.6302 15.5217 12.5301 18.8473 12.4997 18.939H12.1955C12.1955 18.939 11.0701 15.5392 10.6746 14.3123C10.2488 12.9981 8.64677 13.0024 8.64677 13.0024H5V22.9981H8.64846V17.0615H8.95264L11.0819 22.998H13.615L15.7443 17.0659H16.0485V22.998H19.6969V13.0024H16.0485ZM29.3766 13.0024C29.3766 13.0024 28.3069 13.0854 27.805 14.0504L25.2212 18.939H24.917V13.0024H21.2685V22.9981H24.7142C24.7142 22.9981 25.8346 22.9108 26.3365 21.9502L28.8697 17.0615H29.1739V22.9981H32.8223V13.0024H29.3766ZM34.4446 17.5418V22.9981H38.093V19.8122H42.0457C43.7694 19.8122 45.2278 18.8648 45.7702 17.5433H34.4446V17.5418Z"
          fill="#808082"
        />
        <path
          d="M42.0474 13.0024H33.9326C34.3382 14.9061 35.9959 16.4328 38.1387 16.9305C38.6361 17.0466 39.1493 17.1053 39.6647 17.1052H45.9189C45.9747 16.8782 46 16.6468 46 16.4067C46.0001 14.5262 44.2308 13.0024 42.0474 13.0024Z"
          fill="#808082"
        />
      </g>
      <defs>
        <clipPath id="clip0_793_7417">
          <rect
            fill="white"
            height="10"
            transform="translate(5 13)"
            width="41"
          />
        </clipPath>
      </defs>
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
